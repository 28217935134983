<template>
  <div class="redeem">
    <Header />
    <HeaderBottom />
    <b-container class="redeem-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link to="/orders"
            ><b-icon class="mx-2" icon="chevron-left"></b-icon
            ><span>Kembali</span></b-link
          >
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="redeem-total-detail mb-4">
          <div class="review-title">
            <b-row class="justify-content-center my-2">
              <h4>Penjualan Reksa Dana</h4>
            </b-row>
          </div>
          <div class="order-detail">
            <b-row class="mt-4">
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-left">
                <p class="order-title">No.Order</p>
                <h2 class="order-number">{{ redeem.code }}</h2>
              </div>
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-right">
                <p class="date-title">Tanggal</p>
                <h3 class="date">
                  {{ moment(redeem.created_at).format(" DD MMM YYYY") }}
                </h3>
              </div>
            </b-row>
          </div>
          <hr />
          <div class="fund-name mt-4">
            <b-row>
              <div class="col-2 col-lg-1 text-left mb-2">
                <div v-if="redeem.product.image == null">
                  <img
                    :src="
                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      redeem.product.fund_name
                    "
                  />
                </div>
                <div v-else>
                  <img
                    :src="`${imageCore}/images/products/${redeem.product.image}`"
                    alt="Fund Image"
                  />
                </div>
              </div>
              <div class="col-10 px-4 col-md-6">
                <h4>{{ redeem.product.fund_name }}</h4>
                <p>
                  {{ redeem.product.fund_type }}
                </p>
              </div>
            </b-row>
          </div>

          <div class="unit">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">Unit</p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">{{ formatNab(redeem.amount_unit) }} unit</p>
              </div>
            </b-row>
          </div>
          <hr />

          <div class="nav-unit">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">
                  NAV/unit per
                  {{ moment(redeem.nav_date).format(" DD MMM YYYY") }}
                </p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">
                  {{ redeem.product.fund_ccy
                  }}{{ formatPrice(redeem.product.nav.nav_per_unit) }}
                </p>
              </div>
            </b-row>
          </div>
          <hr />

          <div class="redeem-review">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">Estimasi Penjualan Kembali</p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">
                  {{ redeem.product.fund_ccy
                  }}{{ formatPrice(redeem.amount_nominal) }}
                </p>
              </div>
            </b-row>
          </div>
          <hr />

          <template v-if="this.redeem.fee_sales != null">
            <div class="redeem-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">
                    Biaya Penjualan Kembali {{ redeem.fee_sales }}%
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="variant">
                    {{ redeem.product.fund_ccy }}{{ formatPrice(feeSell) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <div class="total-redeem">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ redeem.product.fund_ccy }}{{ formatPrice(totalTrx) }}
                  </p>
                </div>
              </b-row>
            </div>
          </template>
          <template v-else>
            <div class="redeem-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Penjualan Kembali</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p v-if="user.individual.sales_id != null" class="variant">
                    {{
                      redeem.product.fee_sell_text
                        ? redeem.product.fee_sell_text
                        : "-"
                    }}
                  </p>
                  <p v-else class="value">Gratis</p>
                </div>
              </b-row>
            </div>
            <hr />
            <div class="total-redeem">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ redeem.product.fund_ccy
                    }}{{ formatPrice(redeem.amount_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
          </template>

          <!-- <div class="custodian-bank">
            <b-row class="mt-2">
              <b-col class="my-auto">
                <p class="variant">Bank Kustodian</p>
              </b-col>
              <b-col class="my-auto">
                <p class="custodian-bank-name">
                  {{
                    product.rekening_product
                      ? product.rekening_product.bank.account_name
                      : "-"
                  }}
                </p>
              </b-col>
            </b-row>
          </div> -->
        </b-card>

        <b-card class="redeem-total-detail mb-4">
          <div class="transaction-status ml-3">
            <b-row>
              <p>Status Transaksi</p>
            </b-row>
          </div>

          <div class="stepper d-flex flex-column">
            <template v-if="redeem.status == 'waiting sales'">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="text-muted"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Penjualan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                  </p>
                </div>
              </div>

              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="primary-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Menunggu Verifikasi Sales</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Diverifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Diverifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Berhasil</p>
                </div>
              </div>
            </template>

            <template v-else-if="redeem.status == 'waiting confirm'">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Penjualan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                  </p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon
                        font-scale="1.5"
                        class="muted-icon-color"
                        icon="check2-circle"
                      ></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Menunggu Verifikasi Sales</p>
                    <p class="detail mt-n2">
                      <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                    </p>
                  </div>
                </div>
              </template>

              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="primary-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Menunggu Verifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Diverifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Berhasil</p>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Penjualan Diterima</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                  </p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon
                        font-scale="1.5"
                        class="muted-icon-color"
                        icon="check2-circle"
                      ></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Menunggu Verifikasi Sales</p>
                    <p class="detail mt-n2">
                      <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                    </p>
                  </div>
                </div>
              </template>

              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Verifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                  </p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Diverifikasi</p>
                  <p class="detail mt-n2">
                    <!-- {{ moment(redeem.created_at).format(' DD MMM YYYY, H:mm:ss') }} WIB -->
                  </p>
                </div>
              </div>

              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="primary-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Penjualan Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Penjualan Berhasil</p>
                </div>
              </div>
            </template>
          </div>

          <div class="estimation-complete">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">Estimasi Selesai</p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">
                  3 hari bursa <br />
                  <small>setelah tanggal transaksi</small>
                </p>
              </div>
            </b-row>
          </div>
        </b-card>

        <b-card class="redeem-total-detail mb-4">
          <div class="payment-method mt-4">
            <h3>Rekening Bank Anda</h3>
            <h2>Uang hasil penjualan akan ditransfer oleh Bank Kustodian.</h2>
          </div>

          <div class="upload-proof mt-4">
            <div class="bank-title">
              <b-row class="justify-content-md-start ml-1 mb-4">
                <div class="ekyc-form-upload">
                  <b-row>
                    <b-col>
                      <template>
                        <div class="dropbox">
                          <b-row>
                            <div class="col-lg-3 col-md-6">
                              <div class="bank-image text-center mx-4 mt-2">
                                <img
                                  :src="`${imageCore}/${
                                    redeem.account.banks.logo ||
                                    user.institutional.account.banks.logo
                                  }`"
                                  max-height="26"
                                  max-width="86"
                                  alt="Bank Image"
                                />
                                <p class="mt-1">
                                  {{
                                    redeem.account.banks.bank_name ||
                                    user.institutional.account.banks.bank_name
                                  }}
                                </p>
                              </div>
                            </div>
                            <div
                              class="col-lg-9 col-md-6 text-center text-lg-left"
                            >
                              <div class="payment-account mt-2">
                                <p>
                                  {{
                                    redeem.account.account_number ||
                                    user.individual.account.account_number
                                  }}
                                </p>
                                <h3>
                                  {{
                                    redeem.account.account_name ||
                                    user.institutional.account.account_name
                                  }}
                                </h3>
                              </div>
                            </div>
                          </b-row>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </b-row>
            </div>
          </div>
        </b-card>

        <b-card class="redeem-total-detail mb-4">
          <div class="price-terms mt-4">
            <b-row>
              <div class="col-lg-12 col-md-6">
                <p>
                  <img
                    src="@/assets/img/icons/product/transaction-rules.svg"
                    alt="Ketentuan Transaksi"
                    class="mx-2"
                  />Ketentuan Transaksi
                </p>
              </div>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Transaksi sebelum 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="before-detail text-justify">
                Batas waktu konfirmasi Penjualan Reksa Dana paling lambat
                diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul 13:00
                WIB untuk diproses menggunakan harga NAV/Unit di hari bursa yang
                sama.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-after ml-3">
            <b-row class="mt-2">
              <p class="after">Transaksi setelah 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="after-detail text-justify">
                Transaksi Penjualan yang diterima dan tercatat melebihi batas
                waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                NAV/Unit hari berikutnya.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Biaya dan Perpajakan</p>
            </b-row>
            <b-row>
              <p class="before-detail">
                Biaya dan Perpajakan akan timbul atas dasar ketentuan peraturan
                perundangan-undangan yang berlaku, sesuai dengan transaksi yang
                dimiliki.
              </p>
            </b-row>
          </div>
        </b-card>
        <b-button block to="/orders" class="mt-2 btn-tertiary py-2"
          >Lihat Aktivitas Dalam Proses</b-button
        >
        <!-- <b-button block href="#" class="mt-3 btn-outline-primary"
          >Batalkan Transaksi</b-button
        > -->
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "WaitingForRedeemVerification",
  metaInfo: {
    title: "Detail Proses Penjualan | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  data() {
    return {
      imageCore: process.env.VUE_APP_IMAGE_URL,
      code: null,
      redeem: {
        product: {
          fund_code: "",
          nav: {
            nav_date: null,
          },
        },
      },
      fund_code: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
    totalTrx: function () {
      const feeSellTrx =
        (this.redeem.fee_sales / 100) * parseInt(this.redeem.amount_nominal);
      const total = parseInt(this.redeem.amount_nominal) - parseInt(feeSellTrx);
      return total;
    },
    feeSell: function () {
      const feeSellTrx =
        (this.redeem.fee_sales / 100) * parseInt(this.redeem.amount_nominal);
      return feeSellTrx;
    },
  },
  created() {
    this.code = this.$route.params.code;
    this.fund_code = this.$route.params.fund_code;
    this.fetchRedeemGet();
  },
  methods: {
    async fetchRedeemGet() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}redeem-get?code_sell=${this.code}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.redeem = response.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.redeem {
  background: #f3f4f6;
}
.redeem-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.redeem-total-detail {
  padding: 20px;
  width: 600px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;
  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
    text-decoration: none;
  }
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.nav-unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.redeem-review {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.order-detail {
  .order-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
  .order-number {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
  }

  .date-title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
  }
  .date {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
  }
}

.redeem-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-redeem {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.stepper {
  .line {
    width: 2px;
    background-color: lightgrey !important;
  }
  .lead {
    font-size: 1.1rem;
  }
}

.transaction-status p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.bank-image {
  img {
    width: 40px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #4b5563;
    margin-bottom: 0px;
  }
}

.payment-method {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.success-transaction-status {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.payment-accepted {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.unsuccess-transaction-status {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #5c0dab;
  }
}

.primary-icon-color {
  color: $primaryColor;
}

.success-transaction-status {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.estimation-complete {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #111827;
  }
}

.muted-icon-color {
  color: #d1d5db;
}

.payment-account {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
    margin-bottom: 0px;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.dropbox {
  outline: 2px solid #d1d5db; /* the dash box */
  background: #f3f4f6;
  color: dimgray;
  padding: 10px 10px;
  height: 100px; /* minimum height */
  width: 510px; /* minimum height */
  position: relative;
  border-radius: 5px;
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }
}

.btn-tertiary {
  width: 600px;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  .dropbox {
    width: 100%;
    height: 200px;
  }
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }
  p {
    font-size: 14px !important;
  }
}
</style>
